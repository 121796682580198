import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { PostItem } from "../components/PostItem"
import Styles from "../styles/components/PostRoll.module.scss"

type Props = {
    data?: {
        allMarkdownRemark?: {
            edges?: any[];
        };
    };
};

const PostRoll = ( { data }: Props ) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'edges' does not exist on type '{ edges?:... Remove this comment to see the full error message
    const { edges: posts } = data.allMarkdownRemark
    return (
        <ul className={ Styles.container }>
            { posts &&
                // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'post' implicitly has an 'any' typ... Remove this comment to see the full error message
                posts.map( ( { node: post } ) => (
                    <PostItem post={ post } key={ post.id } />
                ) ) }
        </ul>
    )
}

export default () => {
    const query = graphql`
            query PostRollQuery {
                allMarkdownRemark(
                sort: { order: DESC, fields: [frontmatter___date] }
                filter: { frontmatter: { templateKey: { eq: "post" } } }
                ) {
                edges {
                    node {
                    excerpt(pruneLength: 400)
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        path
                        description
                        templateKey
                        date(formatString: "MMMM DD, YYYY")
                        featuredpost
                        featuredimage 
                        {
                          childImageSharp {
                            fluid(maxWidth: 400, quality: 100) {
                              ...GatsbyImageSharpFluid
                            }
                          }
                        }
                    }
                    }
                }
                }
            }
            `
    return (
        <StaticQuery
            query={ query }
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            render={ ( data: any, count: any ) => <PostRoll data={ data } count={ count } /> }
        /> )
}
