import React from 'react'
import { Link, graphql } from 'gatsby'
import Styles from "../styles/templates/IndexPageTemplate.module.scss"
import { Layout } from '../components/Layout'
import Features from '../components/Features'
import PostRoll from '../components/PostRoll'


export const IndexPageTemplate = () => (
    <div className={ Styles.container } >
        <div className={ Styles.content }>
            <div>
                <h1 className={ Styles.h1 }>ちゅうがえりろん</h1>
            </div>
            <p className={ Styles.description }>「ちゅうがえりろん」は、博士と宙(そら)くんのやりとりの中で宙返りのコツを学んでいくWebサイトです</p>
            <section>
                <div className={ Styles.post_roll_container }>
                    <h3 className={ Styles.new_post_heading }>
                        新着記事
                    </h3>
                    <PostRoll />
                </div>
            </section >
        </div>
    </div >
)

type IndexPageProps = {
    data?: {
        markdownRemark?: {
            frontmatter?: any;
        };
    };
};

const IndexPage = ( { data }: IndexPageProps ) => {
    return (
        <Layout>
            <IndexPageTemplate
            />
        </Layout>
    )
}

export default IndexPage

// export const pageQuery = graphql`
//   query IndexPageTemplate {
//     markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
//       frontmatter {
//         title
//         image {
//           childImageSharp {
//             fluid(maxWidth: 2048, quality: 100) {
//               ...GatsbyImageSharpFluid
//             }
//           }
//         }
//         heading
//         subheading
//         mainpitch {
//           title
//           description
//         }
//         description
//         intro {
//           blurbs {
//             image {
//               childImageSharp {
//                 fluid(maxWidth: 240, quality: 64) {
//                   ...GatsbyImageSharpFluid
//                 }
//               }
//             }
//             text
//           }
//           heading
//           description
//         }
//       }
//     }
//   }
// `
